const newsletterElement = $('.footer-newsletter');

$(document).ready(function() {
    $('body').on('pfah.callback', function (e, id, result) {
        var formID = $('.footer-newsletter .pfah-wrapper').attr('data-id');
        var formEl = $('.footer-newsletter .pfah-wrapper .pfah-form');

        var event = $(formEl).attr('data-segment-event');
        var name = $(formEl).attr('data-segment-name') ? $(formEl).attr('data-segment-name') : $(location).attr("href");
        var location = $(formEl).attr('data-segment-location');
        var category = $(formEl).attr('data-segment-category');

        if (id === formID && result === 'done') {
            analytics.track(event, {
                name: name,
                location: location,
                category: category,
                label: 'name: '+name+' | location: '+location
            });
        }
    });

});